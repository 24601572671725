import { takeEvery } from 'redux-saga/effects'
import {
  galleryConstants,
  eventsConstants,
  reservationsConstants
} from '../constants'
import { loadGallerySaga } from './gallery.sagas'
import { loadEventsSaga } from './events.sagas'
import { loadReservationsSaga } from './reservations.sagas'

export function* watchGallery() {
  yield takeEvery(galleryConstants.GALLERY_INIT, loadGallerySaga)
}

export function* watchEvents() {
  yield takeEvery(eventsConstants.EVENTS_INIT, loadEventsSaga)
}

export function* watchReservations() {
  yield takeEvery(reservationsConstants.RESERVATIONS_INIT, loadReservationsSaga)
}
