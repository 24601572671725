export const uiConstants = {
  videos: [
    {
      title: 'Special Forces Training',
      url: 'IpRuLRDPqaE'
    },
    {
      title: 'Glock 17 Gen5 T4E',
      url: 'D18QUpaVh7o'
    },
    {
      title: 'HDP50',
      url: 'Vl43-nFlZog'
    },
    {
      title: 'HDS68',
      url: 'Nj8QJq9ts4U'
    },
    {
      title: 'Safety First - Facts',
      url: 'IrnB6uOF14M'
    },
    {
      title: 'Safety First - Safety and warning instructions',
      url: 'RCKTQJIYiws'
    }
  ],
  staff: [
    {
      name: 'Francesca',
      phone: '+393297634316'
    },
    {
      name: 'Mauro',
      phone: '+393337248456'
    },
    {
      name: 'Luca',
      phone: '+393494623924'
    },
    {
      name: 'Paolo',
      phone: '+393336461798'
    },
    {
      name: 'Simone',
      phone: '+393772114627'
    },
    {
      name: 'Andrea',
      phone: '+393475589539'
    },
    {
      name: 'Gian Pietro',
      phone: '+393401148117'
    }
  ],
  week: ['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom']
}
