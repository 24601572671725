import { put, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { reservationsRef } from '../../database'
import moment from 'moment'
import { onSnapshot, query, where } from 'firebase/firestore'

import { updateReservations, removeReservations } from '../actions'

export let reservationsSnap

export function* loadReservationsSaga() {
  try {
    const startDate = moment.utc().add('-7', 'days').unix()
    const listener = eventChannel((emit) => {
      const q = query(
        reservationsRef,
        where('date', '>=', startDate),
        where('confirmed', '==', true)
      )
      reservationsSnap = onSnapshot(q, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          emit(change)
        })
      })
      return () => reservationsSnap()
    })
    while (true) {
      const change = yield take(listener)
      const data = change.doc.data()
      const id = change.doc.id
      if (id === '_._') continue
      if (change.type === 'added') {
        yield put(updateReservations({ id, ...data }))
      }
      if (change.type === 'modified') {
        yield put(updateReservations({ id, ...data }))
      }
      if (change.type === 'removed') {
        yield put(removeReservations({ id, ...data }))
      }
    }
  } catch (error) {
    console.log(error)
  }
}
