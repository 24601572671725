import { reservationsConstants } from '../constants'

export const loadReservations = () => {
  return {
    type: reservationsConstants.RESERVATIONS_INIT
  }
}

export const updateReservations = (payload) => {
  return {
    type: reservationsConstants.RESERVATIONS_UPDATE,
    payload
  }
}

export const removeReservations = (payload) => {
  return {
    type: reservationsConstants.RESERVATIONS_REMOVE,
    payload
  }
}
